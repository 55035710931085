import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import Img from 'gatsby-image'
import styles from './common.module.css'
import ReactHtmlParser from 'react-html-parser'

const PageTemplate = ({ data }) => {
  const page = data.allNodePage.edges[0].node

  let page_body_elements = new ReactHtmlParser(page.body.processed, {
    transform: function transform(node) {
      if (node.type === 'tag' && node.name === 'img') {
        let uuid = node.attribs['data-entity-uuid']
        let i = 0
        for (i = 0; i < data.allFileFile.edges.length; i++) {
          if (
            data.allFileFile.edges[i].node.uuid === uuid &&
            data.allFileFile.edges[i].node.localFile
          ) {
            return (
              <Img
                fluid={
                  data.allFileFile.edges[i].node.localFile.childImageSharp.fluid
                }
              />
            )
          }
        }
      }

      return undefined
    },
  })

  let page_component = (
    <Layout>
      <div>
        <h1>{page.title}</h1>
        {page_body_elements}
      </div>
    </Layout>
  )

  return page_component
}

export default PageTemplate

export const query = graphql`
  query($nid: Int!) {
    allNodePage(filter: { nid: { eq: $nid } }) {
      edges {
        node {
          nid
          title
          body {
            processed
          }
        }
      }
    }
  }
`
